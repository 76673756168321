.center {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.horizontal-center {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.vertical-center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.vertical-center-10 {
    position: relative;
    top: 40%;
    transform: translateY(-50%);
}
