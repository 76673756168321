.normal-link {
    color: inherit;
    padding: 0;
    margin: 0;
    text-decoration: none;
}

.menu-td {
    padding: 0;
    margin: 0;
}

.dropdown {
    float: none;
    border: none;
    outline: none;
    /* position: fixed; */
    background-color: inherit;
}

.dropdown:hover {
    background-color: #bbb;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: inherit;
    z-index: 1;
}

.dropdown-content a {
    float: none;
    display: block;
    text-align: left;
}

.dropdown-content a:hover {
    background-color: #aaa;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.welcome {
    margin: 0;
    left: 10%;
    width: 264px;
    animation-name: welcome;
    animation-duration: 0.5s;
}

@keyframes welcome {
    from {
        left: 0%;
    }
    to {
        left: 10%;
    }
}

.welcome-content {
    margin: 0;
    margin-top: 32px;
    right: 50%;
    width: 1008px;
    transform: translateX(50%);
    animation-name: welcome-content;
    animation-duration: 0.5s;
}

@keyframes welcome-content {
    from {
        right: 0%;
    }
    to {
        right: 50%;
    }
}
